import React from 'react';
import { PageBySlugQuery } from '@/apollo/generated';
import Website404 from '@/components/website/page/404';
import WebsitePageBlock from '@/components/website/page/block';

interface Props {
  page?: PageBySlugQuery['pageBySlug'];
}

const WebsitePage: React.ComponentType<Props> = ({ page }) => {
  if (!page) return <Website404 />;

  const sortedBlocks = [...(page.blocks || [])].sort(
    (a, b) => (a?.position ?? 0) - (b?.position ?? 0)
  );

  if (sortedBlocks.length === 0) return null;

  return (
    <div className="my-16 flex flex-col gap-16 md:my-20 md:gap-20">
      {sortedBlocks.map((block, index) =>
        block ? (
          <WebsitePageBlock
            key={`page-${page.id}-block-${block.id ?? 0}`}
            allBlocks={sortedBlocks}
            block={block}
            index={index}
          />
        ) : null
      )}
    </div>
  );
};

export default WebsitePage;
